<template>
  <div class="breadcrumbs">
    <div v-for="(item, index) of routeList" :key="item.title" class="breadcrumbs__item">
      <router-link
        v-if="index === 0"
        :to="item.href"
        class="breadcrumbs__item--link"
      >
        <span class="breadcrumbs__text">{{item.title}}</span>
      </router-link>
      <div
        v-else-if="index === routeList.length-1"
        class=""
      >
        <span class="breadcrumbs__text">{{item.title.charAt(0).toUpperCase() + item.title.slice(1)}}</span>
      </div>
      <router-link
        v-else
        :to="item.href"
        class="breadcrumbs__item--link"
      >
        <span class="breadcrumbs__text">{{item.title.charAt(0).toUpperCase() + item.title.slice(1)}}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import './Breadcrumbs.styl'

  export default {
    name: 'Breadcrumbs',
    computed: {
      routeList () {
        const route = this.$route.path.split('/')
        route[0] = '/'

        let currentRoute = ''

        return route.map((elem, index) => {
          if (index !== 0) {
            currentRoute = currentRoute + '/' + elem
            return {
              title: elem,
              href: currentRoute,
            }
          }
          return {
            title: 'Home',
            href: elem,
          }
        })
      },
    },
  }
</script>
