<template>
  <div class="box box--mapBlock">
    <div class="box__inner">
      <div class="mapBlock js-tabBox mapBlock--detail">
        <div class="mapBlock__header">
          <div class="mapBlock__header-top">
            <div class="mapBlock__header-inner">
              <div class="mapBlock__breadcrumbs">
                <Breadcrumbs/>
              </div>
              <div class="mapBlock__info">
                <div class="mapBlock__info-left">
                  <div class="mapBlock__info-title">Search:</div>
                  <router-link class="mapBlock__info-link" to="map-search">edit search</router-link>
                </div>
                <div class="mapBlock__info-right">
                  <div class="mapBlock__info-list">
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--route">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="placeholder"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span class="text text--departure">New York-area</span>
                        <span class="arrow">
                          <SvgIcon name="long-left-arrow"/>
                        </span>
                        <span class="text text--arrival">Orlando International Airport (MCO)</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--way">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="airplane"/>
                      </div>
                      <div class="mapBlock__info-list-text">One way</div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--date">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="calendar"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span class="text text--desktop">July 25, 2018</span>
                        <span class="text text--mobile">07/25/18</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--count">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="user"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span>3</span>
                        <span class="text text--desktop">&ensp;Passengers</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--type">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="charter-flight"/>
                      </div>
                      <div class="mapBlock__info-list-text">Charter Seats, Commercial Seats</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mapBlock__header-bottom js-tabNav-wrap">
            <div class="mapBlock__tab-nav">
              <div class="mapBlock__tab-nav-item js-tabNavItem" data-id="aircraft" @click="tabActivate">
                <span class="text">Aircraft</span>
              </div>
              <div class="mapBlock__tab-nav-item js-tabNavItem" data-id="aircraftProvider" @click="tabActivate">
                <span class="text">Aircraft provider</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mapBlock__content">
          <router-link class="mapBlock__sidebar-title mapBlock__sidebar-title--aircraft" to="map-search">
            <div class="mapBlock__sidebar-title-inner">
              <span class="text">Map</span>
              <span class="icon">
                  <SvgIcon name="down-arrow"/>
                </span>
            </div>
          </router-link>
          <router-link class="mapBlock__sidebar-title mapBlock__sidebar-title--aircraft" to="search-results">
            <div class="mapBlock__sidebar-title-inner">
              <span class="text">Search results</span>
              <span class="icon">
                  <SvgIcon name="down-arrow"/>
                </span>
            </div>
          </router-link>
          <div class="mapBlock__sidebar-content mapBlock__sidebar-content--aircraft">
            <div class="mapBlock__tab-content">
              <div class="mapBlock__tab-content-item js-tabContentItem">
                <div class="mapBlock__tab-content-inner">
                  <vue-scroll :ops="ops">
                    <div class="mapBlock__detail js-customScroll">
                      <div class="mapBlock__bidding mapBlock__bidding--buy-it-now">
                        <div class="mapBlock__bidding-column">
                          <div class="mapBlock__bidding-title">Time left</div>
                          <div class="mapBlock__bidding-text">8h:30min</div>
                        </div>
                        <div class="mapBlock__bidding-column">
                          <div class="mapBlock__bidding-title">Current bid price</div>
                          <div class="mapBlock__bidding-price js-current-bid-price">$10,500.00</div>
                        </div>
                        <div class="mapBlock__bidding-column">
                          <form
                            class="form form--bidding js-ajaxForm"
                            :class="{'loading' : loading}"
                            data-href="/"
                            data-action="bidding"
                            id="biddingForm"
                            @submit.prevent="submitHandler"
                          >
                            <label class="form__label form__label--text price">
                              <div class="form__field-wrapper">
                                <input
                                  class="form__field"
                                  type="text"
                                  placeholder="Enter bid"
                                  autocomplete="off"
                                  v-model.lazy="bid"
                                  v-money="money"
                                  :disabled="loading"
                                />
                              </div>
                            </label>
                            <div class="form__label form__label--button">
                              <button class="form__button" :disabled="loading"><span class="text">BID</span></button>
                            </div>
                          </form>
                        </div>
                        <div class="mapBlock__bidding-column">
                          <div class="mapBlock__bidding-title">Book now price</div>
                          <div class="mapBlock__bidding-price js-buy-it-now-price">$15,000.00</div>
                        </div>
                        <div class="mapBlock__bidding-column">
                          <button
                            class="mapBlock__bidding-button js-popup"
                            @click="$modal.show('bookConfirmPopup')"
                            data-mod="black-popup out-close">
                            <span class="text">Book</span>
                          </button>
                        </div>
                      </div>
                      <AircraftDetail v-if="sliderOne" :category="'Aircraft'" :title="'Gulfstream 500 (G500)'"/>
                    </div>
                  </vue-scroll>
                </div>
              </div>
              <div class="mapBlock__tab-content-item js-tabContentItem">
                <div class="mapBlock__tab-content-inner">
                  <vue-scroll :ops="ops">
                    <div class="mapBlock__detail js-customScroll">
                      <AircraftDetail v-if="sliderTwo" :category="'Aircraft provider'" :title="'Fly corporation'"/>
                    </div>
                  </vue-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BookingConfirmPopup/>
  </div>
</template>

<script>
  import Breadcrumbs from '@/components/common/Breadcrumbs/Breadcrumbs'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Tabs } from '@/js/plugins/tabs'
  import AircraftDetail from '@/components/AircraftDetail/AircraftDetail'
  import BookingConfirmPopup from '@/components/common/modals/BookConfirmPopup'
  import { VMoney } from 'v-money'

  export default {
    name: 'AuctionBidding',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      sliderOne: true,
      sliderTwo: false,
      bid: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
      },
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      loading: false,
    }),
    directives: { money: VMoney },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        const type = event.currentTarget.getAttribute('data-id')
        this.tabs.addActiveForClick(event.currentTarget)
        if (type === 'aircraftProvider') {
          this.sliderOne = false
          this.sliderTwo = true
        } else {
          this.sliderOne = true
          this.sliderTwo = false
        }
      },
      submitHandler () {
        if (this.bid === '$ 0.00') {
          return false
        }

        this.loading = true

        const data = {
          bid: this.bid,
        }
        console.log(data)
      },
    },
    components: {
      Breadcrumbs,
      SvgIcon,
      AircraftDetail,
      BookingConfirmPopup,
    },
    metaInfo: {
      meta: [{
        name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
