<template>
  <modal name="bookConfirmPopup" transition="pop-out" @opened="opened" :adaptive="true">
    <div class="bookConfirmPopup" id="bookConfirmPopup">
      <div class="bookConfirmPopup__inner">
        <div class="bookConfirmPopup__title">Dates selected</div>
        <div class="bookConfirmPopup__route">
          <div class="bookConfirmPopup__route-item">
            <div class="bookConfirmPopup__route-title">
              <span class="icon">
                <SvgIcon name="arrival"/>
              </span>
              <span class="text">Inbound:</span></div>
            <div class="bookConfirmPopup__route-text">July 25, 2019</div>
          </div>
          <div class="bookConfirmPopup__route-item">
            <div class="bookConfirmPopup__route-title">
              <span class="icon">
                <SvgIcon name="departures"/>
              </span>
              <span class="text">Outbound:</span></div>
            <div class="bookConfirmPopup__route-text">July 28, 2019</div>
          </div>
        </div>
        <div class="bookConfirmPopup__price">
          <div class="bookConfirmPopup__price-title">Price</div>
          <div class="bookConfirmPopup__price-list">
            <div class="bookConfirmPopup__price-list-item"><span class="caption">Inbound:</span><span class="value">$5,000.00</span>
            </div>
            <div class="bookConfirmPopup__price-list-item"><span class="caption">Outbound:</span><span class="value">$3,000.00</span>
            </div>
          </div>
          <div class="bookConfirmPopup__price-subtitle">Total: all inclusive</div>
          <div class="bookConfirmPopup__price-total">$8,000.00</div>
        </div>
        <span
          class="bookConfirmPopup__button"
          @click="redirectToConfirm"
        >
          <span class="text">Confirm</span>
        </span>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('bookConfirmPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './BookingConfirmPopup.styl'

  export default {
    name: 'bookConfirmPopup',
    methods: {
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')

          container.classList.add('js-bookRoutesPopup')
          document.querySelector('body').appendChild(container)
        }
      },
      redirectToConfirm () {
        this.$modal.hide('bookConfirmPopup')
        // setTimeout(() => {
        //   this.$router.push('booking/confirmation')
        // }, 1000)
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
